<template>
    
    <div class="container">
        <br><br>
        <Channels /><br><br>
        <ChannelStaticList/>
    </div>
</template>
<script>
    import Channels from "../Components/NLSE/Channel/ChannelList.vue";
    import ChannelStaticList from "../Components/NLSE/Channel/ChannelStatic.vue";
    export default {
        name: 'WatchPage',
        components: {
            Channels,
            ChannelStaticList
        },
    };
</script>

<style scoped>
</style>
